import {enableProdMode, importProvidersFrom} from '@angular/core';
import {environment} from './environments/environment';
import {bootstrapApplication} from "@angular/platform-browser";
import {
    LessCoreAuthenticatedGuard,
    LessCoreGuestGuard,
    LessCoreRpcService
} from "@lessname/less-core";
import {provideRouter, TitleStrategy, withRouterConfig} from "@angular/router";
import {AppTranslateTitleStrategy} from "./app/strategy/app-translate-title.strategy";
import {appRoutes} from "./app/app.routes"
import {HttpClient, provideHttpClient} from "@angular/common/http";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {AppMissingTranslationHandler} from "./app/handlers/app-missing-translation.handler";
import {AppComponent} from "./app/components/app.component";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

if (environment.production) {
    enableProdMode();
}

import localeNl from '@angular/common/locales/nl';
import {registerLocaleData} from "@angular/common";

registerLocaleData(localeNl, 'nl_NL');

bootstrapApplication(
    AppComponent,
    {
        providers: [
            {
                provide: 'env',
                useValue: environment
            },

            provideHttpClient(),
            LessCoreRpcService,

            importProvidersFrom(
                TranslateModule.forRoot({
                    missingTranslationHandler: {
                        provide: MissingTranslationHandler,
                        useClass: AppMissingTranslationHandler,
                    },
                    loader: {
                        provide: TranslateLoader,
                        useFactory: function (http: HttpClient) {
                            return new TranslateHttpLoader(
                                http,
                                './assets/i18n/',
                                // Cache busting issue ...
                                `.json`
                            );
                        },
                        deps: [HttpClient],
                    },
                }),
            ),

            LessCoreAuthenticatedGuard,
            LessCoreGuestGuard,
            provideRouter(
                appRoutes,
                withRouterConfig({
                    paramsInheritanceStrategy: 'always',
                }),
            ),

            {
                provide: TitleStrategy,
                useClass: AppTranslateTitleStrategy,
            },
        ]
    }
)
    .catch(err => {
        console.error(err)
    });
