import {ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivationEnd, IsActiveMatchOptions, Router, RouterLink, RouterLinkActive} from '@angular/router';
import {BehaviorSubject, filter, Subscription} from 'rxjs';
import {AsyncPipe, NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from "@angular/common";
import {LessSharedIconComponent} from "@lessname/less-shared";
import {TranslateModule} from "@ngx-translate/core";
import {AppMenuComponent} from "./app-menu.component";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-menu-item',
    standalone: true,
    imports: [
        forwardRef(() => AppMenuComponent),
        LessSharedIconComponent,
        RouterLinkActive,
        TranslateModule,
        NgSwitchDefault,
        NgSwitchCase,
        RouterLink,
        AsyncPipe,
        NgSwitch,
        NgClass,
        NgIf,
    ],
    template: `
        @switch (item.type) {
            @case ('toggle') {
                <button
                        (click)="toggled$.next(!toggled$.getValue())"
                        [ngClass]="(toggled$ | async) ? 'active' : ''"
                        class="menu-item"
                        type="button"
                >
                    {{ item.title | translate }}
                    <less-shared-icon
                            *ngIf="toggled$ | async else close"
                            name="angle-up"
                            [title]="'shared.verb.collapse' | translate"
                    />
                    <ng-template #close>
                        <less-shared-icon
                                [title]="'shared.verb.open' | translate"
                                name="angle-down"
                        />
                    </ng-template>
                </button>
                <ng-container *ngIf="item?.subs as subs">
                    <app-menu
                            *ngIf="toggled$ | async"
                            [level]="level + 1"
                            [items]="subs"
                    />
                </ng-container>
            }
            @case ('route') {
                <a
                        [ngClass]="{'sub-active': toggled$ | async}"
                        [routerLinkActive]="'active selected'"
                        [routerLink]="item.commands"
                        class="menu-item"
                >
                    {{ item.title | translate }}
                </a>
                <ng-container *ngIf="item?.subs as subs">
                    <app-menu
                            [level]="level + 1"
                            [items]="subs"
                    />
                </ng-container>
            }
            @case ('title') {
                <div class="menu-item title">
                    {{ item.title | translate }}
                </div>
            }
            @default {
                <span class="tone-danger">
                    {{ 'shared.noun.unknown' | translate }}: {{ item.type }}
                </span>
            }
        }
    `,
    styles: [`
        :host {
            display: block;
        }

        .menu-item {
            padding: .75rem .75rem .75rem calc((var(--level) * 1.5rem) + .75rem);
            color: var(--tone-secondary-color, #3183fd);
            justify-content: space-between;
            border-radius: 0;
            background: none;
            line-height: 1;
            display: flex;
            height: auto;
            border: none;
            width: 100%;
        }
        
        a.menu-item:not(.active):not(.sub-active) ~ app-menu {
            display: none;
        }

        .menu-item.selected {
            padding-left: calc((var(--level) * 1.5rem) + .75rem - .25rem);
            border-left: .25rem solid hsl(
                    var(--tone-primary-border-hue),
                    var(--tone-primary-border-saturation),
                    calc(var(--tone-primary-border-lightness) + (10 * clamp(-1%, 50% - var(--tone-primary-border-lightness), 1%)))
            );
        }

        .menu-item:not(.title):hover {
            color: var(--tone-secondary-highlight-color, #3183fd);
        }
        
        .menu-item.title {
            background-color: hsl(
                    var(--bg-hue),
                    var(--bg-saturation),
                    calc(var(--bg-lightness) + (15 * clamp(-1%, 50% - var(--bg-lightness), 1%)))
            );
        }
    `],
})

export class AppMenuItemComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'menu-item-wrapper';

    toggled$ = new BehaviorSubject(false);

    subscription: Subscription;

    @Input({ required: true }) level: number;
    @Input({ required: true }) item: {
        type: string;
        title: string;
        commands?: string[],
        subs?: {
            type: string;
            title: string;
        }[];
    };

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.subscription = this
            .router
            .events
            .pipe(filter(event => event instanceof ActivationEnd))
            .subscribe(() => this.toggled$.next(this.isActive()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    isActive(item?: any): boolean {
        item = item || this.item;

        if (item.type === 'route' && this.isRouteActive(item)) {
            return true;
        }

        if (item?.subs) {
            for (const sub of item.subs) {
                if (this.isActive(sub)) {
                    return true;
                }
            }
        }

        return false;
    }

    private isRouteActive(item: any) {
        const options: IsActiveMatchOptions = item?.exact
            ? {paths: 'exact', queryParams: 'exact', fragment: 'ignored', matrixParams: 'ignored'}
            : {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'};

        return this.router.isActive(this.router.createUrlTree(item.commands), options);
    }
}
