import {ActivatedRouteSnapshot, RouterStateSnapshot, Routes} from '@angular/router';
import {LessCoreAuthenticatedGuard} from '@lessname/less-core';
import {inject} from "@angular/core";

export const appRoutes: Routes = [
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadComponent: () => import('./components/app-home.component').then(mod => mod.AppHomeComponent),
        title: 'control.title.home',
        path: '',
    },
    {
        loadComponent: () => import('./components/app-payment-success.component').then(mod => mod.AppPaymentSuccessComponent),
        path: 'payment/success',
    },
    {
        loadComponent: () => import('./components/app-payment-cancel.component').then(mod => mod.AppPaymentCancelComponent),
        path: 'payment/cancel',
    },
    // Products
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../product/site-clippy/control-site-clippy.routes').then(m => m.controlSiteClippyRoutes),
        title: 'control.title.siteClippy.main',
        path: 'site-clippy',
    },
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../product/site-juggler/control-site-juggler.routes').then(m => m.controlSiteJugglerRoutes),
        title: 'control.title.siteJuggler.main',
        path: 'site-juggler',
    },
    // Support
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../support/identity/control-identity.routes').then(m => m.controlIdentityRoutes),
        title: 'shared.noun.identity',
        path: 'identity',
    },
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../support/mailer/control-mailer.routes').then(m => m.controlMailerRoutes),
        title: 'control.title.mailer.main',
        path: 'mailer',
    },
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../support/payment/control-payment.routes').then(m => m.controlPaymentRoutes),
        title: 'shared.noun.payment',
        path: 'payment',
    },
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../support/storage/control-storage.routes').then(m => m.controlStorageRoutes),
        title: 'shared.noun.storage',
        path: 'storage',
    },
    // Global
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../global/control-global.routes').then(m => m.controlGlobalRoutes),
        title: 'shared.noun.global',
        path: 'global',
    },
    // Me
    {
        loadChildren: () => import('../me/control-me.routes').then(m => m.controlMeRoutes),
        title: 'control.title.me.main',
        path: 'me',
    },
];