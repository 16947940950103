import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {AppMenuItemComponent} from "./app-menu-item.component";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-menu',
    standalone: true,
    imports: [
        AppMenuItemComponent
    ],
    template: `
        @for (item of items; track $index) {
            <app-menu-item
                    [style.--level]="level"
                    [level]="level"
                    [item]="item"
            />
        }
    `,
    styles: [`
        :host {
            display: block;
        }
    `],
})

export class AppMenuComponent {
    @HostBinding('class') class = 'menu';

    @Input({ required: true }) items: {
        type: string;
        title: string;
        subs?: {
            type: string;
            title: string;
        }[];
    }[];
    @Input() level: number = 0;
}
