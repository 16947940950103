export const environment = {
    production: false,
    services: {
        'less-identity': {
            uri: 'https://identity.service.lessname.dev/',
            permissions: true,
            token: true,
            queue: true,
        },
        'less-mailer': {
            uri: 'https://mailer.service.lessname.dev/',
            permissions: true,
            token: true,
            queue: true,
        },
        'less-meta': {
            uri: 'https://meta.service.lessname.dev/',
            permissions: true,
            token: true,
            queue: true,
        },
        'less-payment': {
            uri: 'https://payment.service.lessname.dev/',
            permissions: true,
            token: true,
            queue: true,
        },
        'less-storage': {
            uri: 'https://storage.service.lessname.dev/',
            permissions: true,
            token: true,
            queue: true,
        },
        'less-site-clippy': {
            uri: 'https://service.siteclippy.dev/',
            permissions: true,
            token: true,
            queue: true,
        },
        'less-site-juggler': {
            uri: 'https://service.sitejuggler.dev/',
            permissions: true,
            token: true,
            queue: true,
        },
    },
};

import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
