export const mainMenu = [
    {
        title: 'control.menu.main.siteClippy.main',
        commands: ['/site-clippy/site'],
        type: 'route',
    },
    {
        title: 'control.menu.main.siteJuggler.main',
        commands: ['/site-juggler'],
        type: 'route',
    },
    {
        title: 'shared.noun.support',
        type: 'title',
    },
    {
        title: 'shared.noun.identity',
        type: 'toggle',
        subs: [
            {
                commands: ['/identity/account'],
                title: 'shared.noun.accounts',
                type: 'route',
            },
            {
                commands: ['/identity/consumer'],
                title: 'shared.noun.consumers',
                type: 'route',
            },
            {
                commands: ['/identity/producer'],
                title: 'shared.noun.producers',
                type: 'route',
            },
        ],
    },
    {
        title: 'control.menu.main.mailer.main',
        type: 'toggle',
        subs: [
            {
                commands: ['/mailer/template'],
                title: 'shared.noun.templates',
                type: 'route',
            },
            {
                commands: ['/mailer/layout'],
                title: 'shared.noun.layouts',
                type: 'route',
            },
            {
                commands: ['/mailer/sender'],
                title: 'shared.noun.senders',
                type: 'route',
            },
            {
                commands: ['/mailer/mail'],
                title: 'shared.noun.mails',
                type: 'route',
            },
        ],
    },
    {
        title: 'shared.noun.payment',
        type: 'toggle',
        subs: [
            {
                commands: ['/payment/subscription'],
                title: 'shared.noun.subscription',
                type: 'route',
            },
        ],
    },
    {
        title: 'shared.noun.storage',
        type: 'toggle',
        subs: [
            {
                commands: ['/storage/image'],
                title: 'shared.noun.image',
                type: 'route',
            },
            {
                commands: ['/storage/file'],
                title: 'shared.noun.file',
                type: 'route',
            },
        ],
    },
    {
        title: 'shared.noun.global',
        type: 'title',
    },
    {
        commands: ['/global/queue'],
        title: 'shared.noun.queue',
        type: 'route',
    },
    {
        commands: ['/global/permission'],
        title: 'shared.noun.permission',
        type: 'route',
    },
    {
        commands: ['/global/script'],
        title: 'shared.noun.script',
        type: 'route',
    },
    {
        title: 'shared.noun.account',
        type: 'title',
    },
    {
        commands: ['/me/settings'],
        title: 'shared.noun.settings',
        type: 'route',
    },
    {
        commands: ['/me/security'],
        title: 'shared.noun.security',
        type: 'route',
    },
    {
        commands: ['/me/sign-out'],
        title: 'shared.verb.signOut',
        type: 'route',
    },
];
